import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { VerticalsDetails, TestRunInputsDetails, TestRunBulkUploadInput, TestRunsBulkUploadResponse } from '../model/execute-testruns.model';
import { TestRunsFetchInputs, TestRunsDetails, UpdateScenarioStepInput, TestRunsUsersDetails, TestRunSaveStatusInput, TrackOrderRequest, SearchEntities, TrackOrderResponse, ErrorRestartStepResponse, ErrorRestartStepInputs, TestRunRetriggerInput, TestRunsList, TestRunExportResponse } from '../model/manage-testruns.model';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';

@Injectable()
export class ManageScenariosService {

  ngxLoaderConfig: NgxUiLoaderConfig;

  constructor(private ngxUiLoaderService: NgxUiLoaderService, private httpClient: HttpClient, private configService: ConfigService) {
    this.ngxLoaderConfig = this.ngxUiLoaderService.getDefaultConfig();
  }

  // Execute Test Runs Section -- START

  getVerticalsDetails(organizationID: number) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.get<VerticalsDetails[]>(baseUrlWithVersion + `/managetestruns/getverticalsdetails?organizationID=${organizationID}`);
  }
  getPartnerDetails(){
  
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl+'/api/v2.0' ;

   
   
    return this.httpClient.get<string[]>((baseUrlWithVersion+'/PartnerMapping/getpartnermappingdetails'));

  }

  storeTestRunsDetails(testRunsInputsDetails: TestRunInputsDetails) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<string[]>(baseUrlWithVersion + '/managetestruns/storetestruninputs', testRunsInputsDetails);
  }

  testRunsBulkUpload(testRunsBulkUploadInput: TestRunBulkUploadInput) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TestRunsBulkUploadResponse>(baseUrlWithVersion + '/managetestruns/bulkupload', testRunsBulkUploadInput);
  }

  // Execute Test Runs Section -- END

  // Manage Test Runs Section -- START

  public getStepStyleClass(status: string, loadIcon: boolean) {
    status = status.toLowerCase();
    let stepClass = '';
    if (loadIcon) {
      switch (status) {
        case "start":
          stepClass = 'fa-cog';
          break;
        case "ready":
          stepClass = 'fa-cog';
          break;
        case "processing":
          stepClass = 'fa-cog';
          break;
        case "pending":
          stepClass = 'fa-clock-o';
          break;
        case "complete":
          stepClass = 'fa-check';
          break;
        case "error":
          stepClass = 'fa-exclamation';
          break;
        case "skipped":
          stepClass = 'fa-fast-forward';
          break;
        default:
          stepClass = 'fa-hourglass';
      }
    }
    stepClass += (' md-step-circle-' + (status == '' ? 'waiting' : status));
    return stepClass;
  }

  public getSearchEntitiesDetails() {
    return this.httpClient.get<SearchEntities[]>('./assets/configuration/searchentities.json');
  }

  getTestRunsUsersDetails(testRunsFetchInputs: TestRunsFetchInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TestRunsUsersDetails[]>(baseUrlWithVersion + '/managetestruns/getusersdetails', testRunsFetchInputs);
  }

  getTestRunsList(testRunsFetchInputs: TestRunsFetchInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TestRunsList>(baseUrlWithVersion + '/managetestruns/gettestrunslist', testRunsFetchInputs);
  }

  getTestRunsDetails(testRunsFetchInputs: TestRunsFetchInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TestRunsDetails>(baseUrlWithVersion + '/managetestruns/gettestrunsdetails', testRunsFetchInputs);
  }

  getOrderEventsDetails(trackOrderRequest: TrackOrderRequest) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TrackOrderResponse>(baseUrlWithVersion + '/order/TrackOrder', trackOrderRequest);
  }

  startScenarioStep(updateScenarioStepInput: UpdateScenarioStepInput) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<boolean>(baseUrlWithVersion + '/managetestruns/startnextstepcomponent', updateScenarioStepInput);
  }

  restartScenarioStep(updateScenarioStepInput: ErrorRestartStepInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ErrorRestartStepResponse>(baseUrlWithVersion + '/managetestruns/restartscenariostep', updateScenarioStepInput).toPromise();
  }

  restartAllScenarioStep(updateScenarioStepInput: ErrorRestartStepInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<ErrorRestartStepResponse>(baseUrlWithVersion + '/managetestruns/restartscenariostep', updateScenarioStepInput);
  }

  updateTestRunSaveStatus(testRunSaveStatusInput: TestRunSaveStatusInput) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<boolean>(baseUrlWithVersion + '/managetestruns/updatesavestatus', testRunSaveStatusInput);
  }

  rerunTestCase(input: TestRunRetriggerInput) {
    var baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<string>(baseUrlWithVersion + `/managetestruns/retriggertestrun`, input);
  }

  getTestRunsExportDetails(testRunsFetchInputs: TestRunsFetchInputs) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<TestRunExportResponse[]>(baseUrlWithVersion + '/managetestruns/getexportdetails', testRunsFetchInputs);
  }

  // Manage Test Runs Section -- END
}
