export class TestRunsUsersDetails {
  public userName: string;
  public userEmail: string;
}

export class TestRunsList {
  totalTestRunsCount: number;
  testRunDetailsList: TestRunDetails[];
}

export class TestRunDetails {
  constructor() {
    this.testRunPrestepDetails = new TestRunScenariosDetails();
    this.scenariosDetailsList = [];
    this.trackingType = '';
    this.trackingIDs = [];
  }
  rn: number;
  testRunId: string;
  testRunName: string;
  userName: string;
  userEmail: string;
  executionDate: string;
  lastModifiedDate: string;
  isSavedRun: boolean;
  isMultiPayload: boolean;
  totalScenariosCount: number;
  isReload: boolean;
  statusCounts: TestRunStatusCount;
  testRunPrestepDetails: TestRunScenariosDetails;
  scenariosDetailsList: TestRunScenariosDetails[];
  trackingType: string;
  trackingIDs: string[];
}

export class TestRunsFetchInputs {
  constructor() {
    this.pageNumber = 1;
    this.isExport = false;
  }
  userEmail: string;
  testRunName: string;
  testRunId: string;
  pageNumber: number;
  searchId: string;
  organizationId?: number;
  statusFilter?: string;
  isExport: boolean;
}

export class TestRunsDetails {
  public totalCount: number;
  public testRunsItemDetailsList: TestRunsItemDetails[];
}

export class TestRunsItemDetails {
  constructor() {
    this.isReload = false;
  }
  testRunId: string;
  testRunName: string;
  userName: string;
  userEmail: string;
  isSavedRun: boolean;
  isMultiPayload: boolean;
  isReload: boolean;
  executionDate: string;
  statusCounts: TestRunStatusCount;
  testRunPrestepDetails: TestRunScenariosDetails;
  scenariosDetailsList: TestRunScenariosDetails[];

  /** 
   * Tracking type for this test run.
   */
  trackingType: string;

  /**
   * Order IDs which can be tracked for events in this Test Run
   */
  trackingIDs: string[];
}

export class TestRunStatusCount {
  waiting: number;
  start: number;
  ready: number;
  processing: number;
  pending: number;
  complete: number;
  error: number;
}

export class TestRunScenariosDetails {
  correlationId: string;
  verticalName: string;
  sectionName: string;
  scenarioId: number;
  scenarioName: string;
  flavourId: number;
  flavourName: string;
  testCaseName: string;
  executionDate: string;
  estimatedCompletionDateTime: string;
  status: string;
  isVisible: boolean;
  stepsDetailsList: TestRunStepsDetails[];
}

export class TestRunStepsDetails {
  componentId: number;
  componentName: string;
  componentDescription: string;
  componentMethod: string;
  componentUrl: string;
  displayTracking: boolean;
  trackingType: string;
  customErrorMessages: string;
  restartDisplayCondition: string;
  enableRefresh: boolean;
  enableErrorEmail: boolean;
  errorEmaillink: string;
  allowProceedOnError: boolean;
  downloadSuccessKey: string;
  downloadErrorKey: string;
  stepRank: number;
  userInput: string;
  stepStatus: string;
  stepResponse: string;
  isPreReqStep: boolean;
  eventsList: Events[];
  withPartner: boolean;
  isManual: boolean;
  componentCreated: string;
  componentLastModified: string;
  estimatedCompletionDateTime: string;
  isChildStep: boolean;
  parentCorrelationID: string;
  parentStepRank: number;
  isManualStep: boolean;
  childScenariosList: TestRunScenariosDetails[];
}

export class TestRunExportResponse {
  testRunId: string;
  testRunName: string;
  correlationId: string;
  scenarioName: string;
  flavourName: string;
  componentName: string;
  componentDescription: string;
  customErrorMessages: string;
  stepRank: number;
  userInput: string;
  stepStatus: string;
  stepResponse: string;
  userName: string;
  userEmail: string;
  testCaseName: string;
  vertical: string;
  section: string;
  executionDate: string;
  componentTeamEmail: string;
}

export class SearchEntities {
  entityId: number;
  entityType: string;
  entityName: string;
  entityDesc: string;
}

export class TrackOrderRequest {
  entityType: string;
  entityID: string;
  eventSequence: string;
}

export class TrackOrderResponse {
  events: Events[];
  payload: string;
}

export class Events {
  eventId: number;
  eventName: string;
  eventDate: string;
  eventTime: string;
  trackingId: string;
  trackingName: string;
  correlationId: string;
  eventStatus: string;
  eventMessage: any;
}

export class UpdateScenarioStepInput {
  correlationId: string;
  stepRank: number;
  completeCurrentStep: boolean;
}

export class ErrorRestartStepInputs {
  constructor() {
    this.parentCorrelationId = '';
    this.parentStepRank = -1;
    this.isPrestep = false;
  }
  correlationId: string;
  stepRank: number;
  parentCorrelationId: string;
  parentStepRank: number;
  isPrestep: boolean;
}

export class ErrorRestartStepResponse {
  constructor() {
    this.isPrestep = false;
  }
  correlationId: string;
  stepRank: number;
  parentCorrelationId: string;
  parentStepRank: number;
  updateStatus: boolean;
  isPrestep: boolean;
}

export class TestRunSaveStatusInput {
  testRunId: string;
  saveStatus: boolean;
}

export class ModalInputs {
  constructor() {
    this.testRunIndex = -1;
    this.scenarioIndex = -1;
    this.stepIndex = -1;
    this.childScenarioIndex = -1;
    this.childStepIndex = -1;
    this.enableNextStep = false;
    this.enableRestart = false;
    this.enableDownload = false;
    this.enableForManualStep = false;
    this.refreshRestartDisabled = false;
    this.disableFileDownload = false;
    this.isPrestep = false;
  }
  
  modalType: string;
  testRunIndex: number;
  scenarioIndex: number;
  stepIndex: number;
  childScenarioIndex: number;
  childStepIndex: number;
  headerText: string;
  buttonText: string;
  bodyText: string;
  requestPayload: string;
  responsePayload: string;
  componentMethod: string;
  componentUrl: string;
  errorPayload: string;
  enableNextStep: boolean;
  enableRestart: boolean;
  enableRefresh: boolean;
  enableErrorEmail: boolean;
  errorEmaillink: string;
  enableForManualStep: boolean;

  //disable condition for worksoft
  refreshRestartDisabled: boolean;
  disableFileDownload: boolean;
  enableDownload: boolean;
  /*** Number of sections visible, out of Request, Response, Error ***/
  visibleSections: number;
  isVisibleRequest: boolean;
  isVisibleResponse: boolean;
  isVisibleError: boolean;
  isPrestep: boolean;

  /*** ADO RunId ***/
  adoTestRunId: string;

  /*** ADO Run Result Id ***/
  adoTestRunResultId: string;

  /*** ADO Test Case Id ***/
  adoTestCaseId: string;

  showResponseJson: boolean;
  showResponseDownload: boolean;
}

export class TestRunsChartCounts {
  constructor() {
    this.totalTestRuns = 0;
    this.totalScenarios = 0;
    this.scenariosSuccessCount = 0;
    this.scenariosErrorCount = 0;
    this.scenariosProcessingCount = 0;
  }
  totalTestRuns: number;
  totalScenarios: number;
  scenariosSuccessCount: number;
  scenariosErrorCount: number;
  scenariosProcessingCount: number;
}

export class CurrentEventMetadata {
  constructor() {
    this.testRunIndex = 0;
    this.scenarioIndex = 0;
    this.stepIndex = 0;
  }
  testRunIndex: number;
  scenarioIndex: number;
  stepIndex: number;
}

export class TestRunInputsDetails {
  constructor() {
    this.verticalName = '-1';
    this.sectionName = '-1';
  }
  public testRunName: string;
  public userName: string;
  public userEmail: string;
  public verticalName: string;
  public sectionName: string;
  public partnerOrgName: string;
  public businessProcessName: string;
  public onboardingName: string;
  public inputsJsonDetails: string;
}

export class ScenariosInputsModel {
  constructor() {
    this.id = 0;
    this.scenarioId = -1;
    this.flavourId = -1;
    this.scenarioName = '';
    this.flavourName = '';
    this.inputsModel = {};
  }
  public id: number;
  public scenarioId: number;
  public flavourId: number;
  public scenarioName: string;
  public flavourName: string;
  public inputsModel: {};
}

export class TestRunRetriggerInput {
  constructor() {
    this.pageNumber = 1;
    this.pageSet = 5;
  }
  userEmail: string;
  userName?: string;
  testRunId: string;
  pageNumber: number;
  pageSet: number;
  searchId: string;
  OrganizationId?: number;
  TestPlanInstanceId?: string;
  ProjectId?: string;
}
